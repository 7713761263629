import axios from "axios"
import GlobalVariable from "./global"
const baseApiUrl = GlobalVariable.BASE_API_URL
const headerText = GlobalVariable.headerText

const monthnames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const visselList = [{ value: 'Rejected', label: 'Rejected' }, { value: 'Cancelled', label: 'Cancelled' }, { value: 'Under Investigation', label: 'Under Investigation' }, { value: 'Approved', label: 'Approved' }]
const addResponseInterceptor = async (axiosInstance) => {
  axiosInstance.interceptors.response.use((response) => {
    return response
  }, async function (error) {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const access_token = error.response.headers['x-auth-token']
      const refresh_token = error.response.headers['x-refresh-token']
      localStorage.setItem("token", access_token)
      localStorage.setItem("refresh_token", refresh_token)
      originalRequest.headers['Authorization'] = `Bearer ${access_token}`
      return axiosInstance(originalRequest)
    }
    return Promise.reject(error)
  })
}

export const DefaultConfig = () => {
  let authAxios
  let authAxiosMulti
  if (localStorage.getItem("userData")) {
    const localStorageToken = localStorage.getItem('token')
    const refreshToken = localStorage.getItem('refresh_token')
    authAxios = axios.create({
      baseURL: baseApiUrl,
      headers: { Authorization: `Bearer ${localStorageToken}`, "x-refresh-token": refreshToken },
      withCredentials: true
    })
    addResponseInterceptor(authAxios)
    authAxiosMulti = axios.create({
      baseURL: baseApiUrl,
      headers: {
        Authorization: `Bearer ${localStorageToken}`,
        "Content-Type": "multipart/form-data",
        "x-refresh-token": refreshToken
      },
      withCredentials: true

    })
    addResponseInterceptor(authAxiosMulti)
  }

  const DateConversion = (dateStr) => {
    if (dateStr) {
      // Create a Date object from the input string
      const date = new Date(dateStr)

      // Get the day, month, and year components
      let day = date.getDate()
      const monthIndex = date.getMonth()
      const year = date.getFullYear()
      if (day < 10) {
        day = `0${day}`
      }
      // Format the date
      const formattedDate = `${day} ${monthnames[monthIndex]} ${year}`

      return formattedDate
    }
    return ''
  }

  const sorter = (sortname, sortorder, filteredData) => {
    const sortData = filteredData
    sortData.sort((a, b) => {
      if (sortname === 'asOfDate') {
        if (sortorder === "asc") {
          return (a[sortname] ? new Date(a[sortname]) : a[sortname]) > (b[sortname] ? new Date(b[sortname]) : b[sortname]) ? 1 : (a[sortname] ? new Date(a[sortname]) : a[sortname]) < (b[sortname] ? new Date(b[sortname]) : b[sortname]) ? -1 : 0
        } else {
          return (b[sortname] ? new Date(b[sortname]) : b[sortname]) > (a[sortname] ? new Date(a[sortname]) : a[sortname]) ? 1 : (b[sortname] ? new Date(b[sortname]) : b[sortname]) < (a[sortname] ? new Date(a[sortname]) : a[sortname]) ? -1 : 0
        }
      } else {
        if (typeof a[sortname] === "number" && typeof b[sortname] === "number") {
          if (sortorder === "asc") {
            return parseFloat(a[sortname]) > parseFloat(b[sortname]) ? 1 : parseFloat(a[sortname]) < parseFloat(b[sortname]) ? -1 : 0
          } else {
            return parseFloat(b[sortname]) > parseFloat(a[sortname]) ? 1 : parseFloat(b[sortname]) < parseFloat(a[sortname]) ? -1 : 0
          }
        } else if (typeof a[sortname] === "string" && typeof b[sortname] === "string") {
          if (sortorder === "asc") {
            return a[sortname].toLowerCase() > b[sortname].toLowerCase() ? 1 : a[sortname].toLowerCase() < b[sortname].toLowerCase() ? -1 : 0
          } else {
            return b[sortname].toLowerCase() > a[sortname].toLowerCase() ? 1 : b[sortname].toLowerCase() < a[sortname].toLowerCase() ? -1 : 0
          }
        } else {
          if (sortorder === "asc") {
            return a[sortname] > b[sortname] ? 1 : a[sortname] < b[sortname] ? -1 : 0
          } else {
            return b[sortname] > a[sortname] ? 1 : b[sortname] < a[sortname] ? -1 : 0
          }
        }
      }
    })
    return sortData
  }

  const selectStyles = {
    container: (provided) => ({
      ...provided,
      marginTop: 0
    }),
    valueContainer: (provided) => ({
      ...provided,
      overflow: "visible"
    }),
    placeholder: (base, state) => ({
      ...base,
      position: "absolute",
      top: (state.hasValue || state.selectProps.inputValue || state.selectProps.menuIsOpen) ? '-45%' : '20%',
      transition: 'top 0.2s, font-size 0.2s',
      fontSize: (state.hasValue || state.selectProps.inputValue || state.selectProps.menuIsOpen) && 14,
      background: "#fff",
      padding: "0 5px",
      fontWeight: 500,
      color: '#000'
    })
  }

  const ReplaceString = (value) => {
    if (value) {
      return new Date(value.replace(/(\d+)(st|nd|rd|th)/, '$1'))
    }
    return ''
  }

  return {
    headerText,
    selectStyles,
    authAxios,
    authAxiosMulti,
    DateConversion,
    sorter,
    visselList,
    ReplaceString
  }
}
