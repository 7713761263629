// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DefaultConfig } from '../../settings/config'
import ToastMessage from '../../components/toast-messages'
import toast from 'react-hot-toast'
import { ApiUrls } from '../../utility/ApiUrls'
import DefaultSettings from '../../settings/pageSettings'

const sorting = DefaultConfig().sorter
const dateConversion = DefaultConfig().DateConversion

let getUserCall = false
const paginateArray = (array, perPage, page) => array.slice((page - 1) * perPage, page * perPage)

const apiCall = async (method, endpoint, data, value) => {
  // console.log(method, endpoint, data)
  const authAxios = DefaultConfig().authAxios
  if (method === 'get') {
    return await authAxios.get(endpoint)
      .then((response) => {
        const respData = response.data
        if (respData) {
          if (respData.success) {
            const resData = respData.data
            return resData
          } else {
            if (!value) {
              if (respData.errorMessage) {
                toast(t => (<ToastMessage t={t} message={respData.errorMessage} status={"danger"} />))
              } else {
                toast(t => (<ToastMessage t={t} message={"Something Went Wrong..."} status={"danger"} />))
              }
              return ''
            }
            return ''
          }
        }
      }).catch((error) => {
        const errorresponse = error.response
        if (errorresponse) {
          const status = errorresponse.status
          if (status === 403) {
            return ''
          } else if (status === 406) {
            toast(t => (<ToastMessage t={t} message={"unauthorized"} status={"danger"} />))
            return ''
          } else if (status === 419) {
            if (!getUserCall) {
              getUserCall = true
              toast(t => (<ToastMessage t={t} message={"cookie"} status={"danger"} />))
              return ''
            }
          } else if (status === 401) {
            localStorage.clear()
            window.location.reload()
          } else {
            // const error = errorresponse.data.error
            toast(t => (<ToastMessage t={t} message={"Something Went Wrong..."} status={"danger"} />))
            return ''
          }
        }
      })
  } else if (method === 'post') {
    return await authAxios.post(endpoint, data)
      .then((response) => {
        const respData = response.data
        if (respData) {
          if (respData.success) {
            const resData = respData.data
            return resData
          } else {
            if (respData.errorMessage) {
              toast(t => (<ToastMessage t={t} message={respData.errorMessage} status={"danger"} />))
            } else {
              toast(t => (<ToastMessage t={t} message={"Something Went Wrong..."} status={"danger"} />))
            }
            return ''
          }
        }
      }).catch((error) => {
        const errorresponse = error.response
        if (errorresponse) {
          const status = errorresponse.status
          if (status === 403) {
            return ''
          } else if (status === 406) {
            toast(t => (<ToastMessage t={t} message={"unauthorized"} status={"danger"} />))
            return ''
          } else if (status === 419) {
            if (!getUserCall) {
              getUserCall = true
              toast(t => (<ToastMessage t={t} message={"cookie"} status={"danger"} />))
              return ''
            }
          } else if (status === 401) {
            localStorage.clear()
            window.location.reload()
          } else {
            // const error = errorresponse.data.error
            toast(t => (<ToastMessage t={t} message={"Something Went Wrong..."} status={"danger"} />))
            return ''
          }
        }
      })
  } else if (method === 'patch') {
    return await authAxios.patch(endpoint, data)
      .then((response) => {
        const respData = response.data
        if (respData) {
          if (respData.success) {
            const resData = respData.data
            return resData
          } else {
            if (respData.errorMessage) {
              toast(t => (<ToastMessage t={t} message={respData.errorMessage} status={"danger"} />))
            } else {
              toast(t => (<ToastMessage t={t} message={"Something Went Wrong..."} status={"danger"} />))
            }
            return ''
          }
        }
      }).catch((error) => {
        const errorresponse = error.response
        if (errorresponse) {
          const status = errorresponse.status
          if (status === 403) {
            return ''
          } else if (status === 406) {
            toast(t => (<ToastMessage t={t} message={"unauthorized"} status={"danger"} />))
            return ''
          } else if (status === 401) {
            localStorage.clear()
            window.location.reload()
          } else {
            // const error = errorresponse.data.error
            toast(t => (<ToastMessage t={t} message={"Something Went Wrong..."} status={"danger"} />))
            return ''
          }
        }
      })
  } else if (method === 'delete') {
    return await authAxios.delete(endpoint)
      .then((response) => {
        const respData = response.data
        if (respData) {
          if (respData.success) {
            const resData = respData.data
            return resData
          } else {
            if (respData.errorMessage) {
              toast(t => (<ToastMessage t={t} message={respData.errorMessage} status={"danger"} />))
            } else {
              toast(t => (<ToastMessage t={t} message={"Something Went Wrong..."} status={"danger"} />))
            }
            return ''
          }
        }
      }).catch((error) => {
        const errorresponse = error.response
        if (errorresponse) {
          const status = errorresponse.status
          if (status === 403) {
            return ''
          } else if (status === 406) {
            toast(t => (<ToastMessage t={t} message={"unauthorized"} status={"danger"} />))
            return ''
          } else if (status === 419) {
            if (!getUserCall) {
              getUserCall = true
              toast(t => (<ToastMessage t={t} message={"cookie"} status={"danger"} />))
              return ''
            }
          } else if (status === 401) {
            localStorage.clear()
            window.location.reload()
          } else {
            // const error = errorresponse.data.error
            toast(t => (<ToastMessage t={t} message={"Something Went Wrong..."} status={"danger"} />))
            return ''
          }
        }
      })
  }
}

export const getAllCurrentFlags = createAsyncThunk("appCommonReducers/getAllCurrentFlags", async () => {
  const method = 'get'
  const endpoint = ApiUrls.GetAllCurrentFlags
  const data = ''
  const resultData = await apiCall(method, endpoint, data)
  if (resultData) {
    const lookupdata = []
    resultData.forEach((element) => {
      lookupdata.push({ value: element.id, label: `${element.name}` })
    })
    return {
      data: lookupdata,
      data1: resultData,
      spinner: true,
      tokenExp: false
    }
  } else {
    return {
      data: [],
      spinner: true,
      tokenExp: false
    }
  }
})


export const getSharedByRegistry = createAsyncThunk("appCommonReducers/getSharedByRegistry", async () => {
  const method = 'get'
  const endpoint = ApiUrls.GetAllRegistry
  const data = ''
  const resultData = await apiCall(method, endpoint, data)
  if (resultData) {
    const mlist = []
    const clist = []
    resultData.forEach((element) => {
      if (element.participatingFlag) {
        mlist.push({ value: element.id, label: element.name })
      }
      clist.push({ value: element.id, label: element.name })
    })
    return {
      mlist,
      clist,
      spinner: true,
      tokenExp: false
    }
  } else {
    return {
      data: [],
      spinner: true,
      tokenExp: false
    }
  }
})


export const getRISCEntryData = createAsyncThunk("appCommonReducers/getRISCEntryData", async (objData) => {
  const method = 'post'
  const endpoint = ApiUrls.GetRISCEntryData
  let startDate = null
  let endDate = null
  console.log(objData)
  if (Object.keys(objData).length > 0 && objData?.Picker) {
    if (objData?.Picker?.length > 1) {
      startDate = new Date(objData?.Picker[0])
      endDate = new Date(objData?.Picker[1])
    } else {
      startDate = new Date(objData?.Picker[0])
    }
  }
  const data = {
    VesselIMO: objData.searchIMO,
    CurrentFlag: '',
    RegistryName: objData.searchCF,
    Status: objData.searchVisselinfo?.value,
    FromCreatedDate: startDate,
    ToCreatedDate: endDate,
    PageNo: objData.page,
    PageSize: objData.perPage,
    SortName: objData.sortname,
    SortOrder: objData.sortorder
  }
  const resultData = await apiCall(method, endpoint, data)
  if (resultData) {
    const submissionsList = resultData.submissionsList
    return {
      data: submissionsList,
      perPage: objData.perPage,
      pageNo: objData.page,
      count: resultData.count,
      spinner: true,
      tokenExp: false
    }
  } else {
    return {
      data: [],
      spinner: true,
      tokenExp: false
    }
  }
})

export const GetSubmissionHistoryById = createAsyncThunk("appCommonReducers/GetSubmissionHistoryById", async (id) => {
  const method = 'get'
  const endpoint = `${ApiUrls.GetSubmissionHistoryById}?submissionId=${id}`
  const data = ''
  const resultData = await apiCall(method, endpoint, data)
  if (resultData) {
    return {
      data: resultData,
      spinner: true,
      tokenExp: false
    }
  } else {
    return {
      data: [],
      spinner: true,
      tokenExp: false
    }
  }
})

export const createSubmission = createAsyncThunk("appCommonReducers/createSubmission", async (wdata) => {
  const method = 'post'
  const endpoint = ApiUrls.CrateSubmission
  const data = wdata.obj
  const resultData = await apiCall(method, endpoint, data)
  if (resultData) {
    const resData = resultData
    return {
      data: resData,
      spinner: true,
      tokenExp: false
    }
  } else {
    return {
      data: null,
      spinner: false,
      tokenExp: false
    }
  }
})

export const UpdateSubmission = createAsyncThunk("appCommonReducers/UpdateSubmission", async (wdata) => {
  const method = 'patch'
  const endpoint = `${ApiUrls.UpdateSubmission}?submissionId=${wdata?.obj?.Id}`
  const data = wdata.obj
  const resultData = await apiCall(method, endpoint, data)
  if (resultData) {
    return {
      data: resultData,
      spinner: true,
      tokenExp: false
    }
  } else {
    return {
      data: null,
      spinner: false,
      tokenExp: false
    }
  }
})

export const GetAllUsers = createAsyncThunk("appCommonReducers/GetAllUsers", async () => {
  const method = 'get'
  const endpoint = ApiUrls.GetAllUsers
  const data = ''
  const resultData = await apiCall(method, endpoint, data)
  if (resultData) {
    return {
      data: resultData,
      spinner: true,
      tokenExp: false
    }
  } else {
    return {
      data: [],
      spinner: true,
      tokenExp: false
    }
  }
})

export const createInviteUser = createAsyncThunk("appCommonReducers/createInviteUser", async (wdata) => {
  const method = 'post'
  const endpoint = ApiUrls.CreateInviteUser
  const data = wdata.obj
  const resultData = await apiCall(method, endpoint, data)
  if (resultData) {
    const resData = resultData
    return {
      data: resData,
      spinner: true,
      tokenExp: false
    }
  }
})

export const updateInviteUser = createAsyncThunk("appCommonReducers/updateInviteUser", async (wdata) => {
  const method = 'post'
  const endpoint = `${ApiUrls.UpdateInviteUser}`
  const data = wdata.obj
  const resultData = await apiCall(method, endpoint, data)
  if (resultData) {
    return {
      data: resultData,
      spinner: true,
      tokenExp: false
    }
  }
})

export const UpdateTermsAndConditions = createAsyncThunk("appCommonReducers/UpdateTermsAndConditions", async () => {
  const method = 'get'
  const endpoint = ApiUrls.UpdateTermsAndConditions
  const data = ''
  const resultData = await apiCall(method, endpoint, data)
  if (resultData) {
    return {
      data: resultData,
      spinner: true,
      tokenExp: false
    }
  } else {
    return {
      data: [],
      spinner: true,
      tokenExp: false
    }
  }
})

export const getData = createAsyncThunk("appCommonReducers/getData", async (wdata) => {
  return wdata
})

export const getHData = createAsyncThunk("appCommonReducers/getHData", async (wdata) => {
  return wdata
})

export const getUData = createAsyncThunk("appCommonReducers/getUData", async (wdata) => {
  return wdata
})

export const getLData = createAsyncThunk("appCommonReducers/getLData", async () => {
  return {
    spinner: false
  }
})

export const commonreducerSlice = createSlice({
  name: 'appCommonReducers',
  initialState: {
    symbolData: [],
    cSpinner: false,

    allData: [],
    totData: [],
    data: [],
    totalCount: 1,
    rowsperpage: 0,
    cdSpinner: false,

    rSpinner: false,
    lSpinner: false,
    tokenExp: false,
    registryData: [],

    searchInfo: '',
    searchVissel: '',
    searchimo: '',
    searchCB: '',
    searchCF: '',
    startDate: null,
    endDate: null,
    searchDate: [],
    sortname: '',
    sortorder: '',

    hallData: [],
    htotData: [],
    hdata: [],
    htotalCount: 1,
    hrowsperpage: 0,

    hSpinner: false,

    hsearchInfo: '',
    hsearchVissel: '',
    hsearchimo: '',
    hsearchCB: '',
    hsearchCF: '',
    hstartDate: null,
    hendDate: null,
    hsearchDate: [],
    hsortname: '',
    hsortorder: '',

    uallData: [],
    utotData: [],
    udata: [],
    utotalCount: 1,
    urowsperpage: 0,
    Spuinner: false,
    usortname: '',
    usortorder: ''

  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getSharedByRegistry.fulfilled, (state, action) => {
        if (action.payload) {
          state.rSpinner = action.payload.spinner
          state.tokenExp = action.payload.tokenExp
          state.registryData = action.payload.mlist

          state.cSpinner = action.payload.spinner
          state.symbolData = action.payload.clist
        }
      })
      .addCase(getAllCurrentFlags.fulfilled, (state, action) => {
        if (action.payload) {
          state.cSpinner = action.payload.spinner
          state.symbolData = action.payload.data
        }
      })
      .addCase(getRISCEntryData.fulfilled, (state, action) => {
        if (action.payload) {
          state.lSpinner = action.payload.spinner
          state.tokenExp = action.payload.tokenExp
          // const symbolData = state.symbolData
          const n = action.payload.data
          for (let m = 0; m < n.length; m++) {
            const element = n[m]
            element.newId = `${m + 1}`
            element.registryName = state.registryData ? state.registryData.find(n => n.value === element.registryId)?.label : ''
            element.createdDate = dateConversion(element.createdDate)
            element.asOfDate = dateConversion(element.asOfDate)
            // element.currentFlag = symbolData.find(find => find.value === element.flagId) ? symbolData.find(find => find.value === element.flagId).label : ''
            if (element?.historyData) {
              const hData = element?.historyData
              hData.registryName = state.registryData ? state.registryData.find(n => n.value === hData.registryId)?.label : ''
              hData.createdDate = dateConversion(hData.createdDate)
              hData.asOfDate = dateConversion(hData.asOfDate)
              // hData.currentFlag = symbolData.find(find => find.value === hData.flagId) ? symbolData.find(find => find.value === hData.flagId).label : ''
              element['historyData'] = hData
            }
          }
          state.allData = n
          state.totData = n
          const perPage = action.payload.perPage
          const count = action.payload.count
          const sortname = state.sortname
          const sortorder = state.sortorder
          const searchInfo = state.searchInfo
          const searchVissel = state.searchVissel
          const searchimo = state.searchimo
          const searchDate = state.searchDate
          const startDate = state.startDate
          const endDate = state.endDate
          const searchCB = state.searchCB
          const searchCF = state.searchCF

          let filterdata = state.totData

          if (searchInfo) {
            filterdata = state.totData.filter(find => find.registryId === searchInfo)
          }

          if (searchVissel) {
            filterdata = filterdata.filter(find => find.status === searchVissel)
          }

          if (searchimo) {
            filterdata = filterdata.filter(find => String(find.vesselIMO)?.toLowerCase().includes(searchimo?.toLowerCase()))
          }

          if (searchDate && searchDate.length === 2) {
            filterdata = filterdata.filter(find => {
              const recordDate = new Date(find.date)
              return recordDate >= startDate && recordDate <= endDate
            })
          }

          if (searchCF) {
            filterdata = filterdata.filter(find => String(find.information)?.toLowerCase().includes(searchCF?.toLowerCase()))
          }

          if (searchCB) {
            filterdata = filterdata.filter(find => String(find.userName)?.toLowerCase().includes(searchCB?.toLowerCase()))
          }
          state.rowsperpage = 0
          state.allData = filterdata
          const sortData = sortorder ? sorting(sortname, sortorder, state.allData) : state.allData
          state.data = paginateArray(sortData, perPage, 1)
          state.totalCount = count
        }
      })
      .addCase(getData.fulfilled, (state, action) => {
        const perPage = action.payload.perPage
        const page = action.payload.page
        state.rowsperpage = page
        const sortname = action.payload.sortname
        const sortorder = action.payload.sortorder
        state.sortname = sortname
        state.sortorder = sortorder

        const searchInfo = action.payload.searchInformation ? action.payload.searchInformation.value : ''
        const searchVissel = action.payload.searchVisselinfo ? action.payload.searchVisselinfo.value : ''
        const searchDate = action.payload.Picker
        const searchimo = action.payload.searchIMO
        // const searchCF = action.payload.searchCF ? (action.payload.searchCF?.label ? action.payload.searchCF?.label : '') : ''
        const searchCF = action.payload.searchCF
        const searchCB = action.payload.searchCB

        const startDate = new Date(searchDate[0])
        const endDate = new Date(searchDate[1])

        state.searchInfo = searchInfo
        state.searchVissel = searchVissel
        state.searchimo = searchimo
        state.searchDate = searchDate
        state.startDate = startDate
        state.endDate = endDate
        state.searchCF = searchCF
        state.searchCB = searchCB

        let filterdata = JSON.parse(JSON.stringify(state.totData))

        if (searchInfo) {
          filterdata = state.totData.filter(find => find.registryId === searchInfo)
        }

        if (searchVissel) {
          filterdata = filterdata.filter(find => find.status === searchVissel)
        }

        if (searchimo) {
          filterdata = filterdata.filter(find => String(find.vesselIMO)?.toLowerCase().includes(searchimo?.toLowerCase()))
        }

        if (searchDate && searchDate.length === 2) {
          filterdata = filterdata.filter(find => {
            const recordDate = new Date(find.date)
            return recordDate >= startDate && recordDate <= endDate
          })
        }

        if (searchCF) {
          filterdata = filterdata.filter(find => String(find.information)?.toLowerCase().includes(searchCF?.toLowerCase()))
        }

        if (searchCB) {
          filterdata = filterdata.filter(find => String(find.userName)?.toLowerCase().includes(searchCB?.toLowerCase()))
        }

        state.allData = filterdata
        const sortData = sortorder ? sorting(sortname, sortorder, state.allData) : state.allData
        state.data = paginateArray(sortData, perPage, page)
        state.totalCount = sortData.length
      })
      .addCase(GetSubmissionHistoryById.fulfilled, (state, action) => {
        if (action.payload) {
          state.hSpinner = action.payload.spinner
          state.tokenExp = action.payload.tokenExp
          const n = action.payload.data
          const symbolData = state.symbolData
          for (let m = 0; m < n.length; m++) {
            const element = n[m]
            element.information = state.registryData ? state.registryData.find(n => n.value === element.registryId)?.label : ''
            element.date = dateConversion(element.createdDate)
            element.asOfDate = dateConversion(element.asOfDate)
            element.currentFlag = symbolData.find(find => find.value === element.flagId) ? symbolData.find(find => find.value === element.flagId).label : ''
          }
          state.hallData = n
          state.htotData = n
          const count = DefaultSettings.perPage
          // state.data = paginateArray(n, count, 1)
          // state.totalCount = n.length

          const sortname = state.hsortname
          const sortorder = state.hsortorder
          const searchInfo = state.hsearchInfo
          const searchVissel = state.hsearchVissel
          const searchimo = state.hsearchimo
          const searchDate = state.hsearchDate
          const startDate = state.hstartDate
          const endDate = state.hendDate
          const searchCB = state.hsearchCB
          const searchCF = state.hsearchCF

          let filterdata = state.htotData

          if (searchInfo) {
            filterdata = state.htotData.filter(find => find.registryId === searchInfo)
          }

          if (searchVissel) {
            filterdata = filterdata.filter(find => find.status === searchVissel)
          }

          if (searchimo) {
            filterdata = filterdata.filter(find => String(find.vesselIMO)?.toLowerCase().includes(searchimo?.toLowerCase()))
          }

          if (searchDate && searchDate.length === 2) {
            filterdata = filterdata.filter(find => {
              const recordDate = new Date(find.date)
              return recordDate >= startDate && recordDate <= endDate
            })
          }

          if (searchCF) {
            filterdata = filterdata.filter(find => String(find.currentFlag)?.toLowerCase().includes(searchCF?.toLowerCase()))
          }

          if (searchCB) {
            filterdata = filterdata.filter(find => String(find.userName)?.toLowerCase().includes(searchCB?.toLowerCase()))
          }
          state.hrowsperpage = 0
          state.hallData = filterdata
          const sortData = sortorder ? sorting(sortname, sortorder, state.hallData) : state.hallData
          state.hdata = paginateArray(sortData, count, 1)
          state.htotalCount = sortData.length
        }
      })
      .addCase(getHData.fulfilled, (state, action) => {
        const perPage = action.payload.perPage
        const page = action.payload.page
        state.hrowsperpage = page
        const sortname = action.payload.sortname
        const sortorder = action.payload.sortorder
        state.hsortname = sortname
        state.hsortorder = sortorder

        const searchInfo = action.payload.searchInformation ? action.payload.searchInformation.value : ''
        const searchVissel = action.payload.searchVisselinfo ? action.payload.searchVisselinfo.value : ''
        const searchDate = action.payload.Picker
        const searchimo = action.payload.searchIMO
        const searchCF = action.payload.searchCF ? (action.payload.searchCF?.label ? action.payload.searchCF?.label : '') : ''
        const searchCB = action.payload.searchCB

        const startDate = new Date(searchDate[0])
        const endDate = new Date(searchDate[1])

        state.hsearchInfo = searchInfo
        state.hsearchVissel = searchVissel
        state.hsearchimo = searchimo
        state.hsearchDate = searchDate
        state.hstartDate = startDate
        state.hendDate = endDate
        state.hsearchCF = searchCF
        state.hsearchCB = searchCB

        let filterdata = state.htotData

        if (searchInfo) {
          filterdata = state.htotData.filter(find => find.registryId === searchInfo)
        }

        if (searchVissel) {
          filterdata = filterdata.filter(find => find.status === searchVissel)
        }

        if (searchimo) {
          filterdata = filterdata.filter(find => String(find.vesselIMO)?.toLowerCase().includes(searchimo?.toLowerCase()))
        }

        if (searchDate && searchDate.length === 2) {
          filterdata = filterdata.filter(find => {
            const recordDate = new Date(find.date)
            return recordDate >= startDate && recordDate <= endDate
          })
        }

        if (searchCF) {
          filterdata = filterdata.filter(find => String(find.currentFlag)?.toLowerCase().includes(searchCF?.toLowerCase()))
        }

        if (searchCB) {
          filterdata = filterdata.filter(find => String(find.userName)?.toLowerCase().includes(searchCB?.toLowerCase()))
        }

        state.hallData = filterdata
        const sortData = sortorder ? sorting(sortname, sortorder, state.hallData) : state.hallData
        state.hdata = paginateArray(sortData, perPage, page)
        state.htotalCount = sortData.length
      })
      .addCase(createSubmission.fulfilled, (state, action) => {
        if (action.payload) {
          state.lSpinner = action.payload.spinner
          state.tokenExp = action.payload.tokenExp
        }
      })
      .addCase(UpdateSubmission.fulfilled, (state, action) => {
        if (action.payload) {
          state.lSpinner = action.payload.spinner
          state.tokenExp = action.payload.tokenExp
        }
      })
      .addCase(GetAllUsers.fulfilled, (state, action) => {
        if (action.payload) {
          state.uSpinner = action.payload.spinner
          state.tokenExp = action.payload.tokenExp
          const sortname = state.usortname
          const sortorder = state.usortorder
          state.usortname = sortname
          state.usortorder = sortorder
          const n = action.payload.data
          for (let m = 0; m < n.length; m++) {
            const element = n[m]
            element.information = state.registryData ? state.registryData.find(n => n.value === element.registryId)?.label : ''
            element.name = `${element.firstName} ${element.lastName ? `${element.lastName}` : ''}`
            if (element.invitedStatusId) {
              element.invitedStatus = element.invitedStatusId === 1 ? 'Pending' : (element.invitedStatusId === 2 ? 'Completed' : '')
            } else {
              element.invitedStatus = element.invitedStatusId === 1 ? 'Pending' : (element.invitedStatusId === 2 ? 'Completed' : '')
            }
          }
          state.uallData = n
          state.utotData = n
          const count = DefaultSettings.perPage
          const sortData = sortorder ? sorting(sortname, sortorder, state.uallData) : state.uallData
          state.urowsperpage = 0
          state.udata = paginateArray(sortData, count, 1)
          state.utotalCount = sortData.length
        }
      })
      .addCase(getUData.fulfilled, (state, action) => {
        const perPage = action.payload.perPage
        const page = action.payload.page
        state.rowsperpage = page
        const sortname = action.payload.sortname
        const sortorder = action.payload.sortorder
        state.usortname = sortname
        state.usortorder = sortorder

        const filterdata = JSON.parse(JSON.stringify(state.utotData))

        state.uallData = filterdata
        const sortData = sortorder ? sorting(sortname, sortorder, state.uallData) : state.uallData
        state.udata = paginateArray(sortData, perPage, page)
        state.utotalCount = sortData.length
      })
      .addCase(getLData.fulfilled, (state, action) => {
        state.lSpinner = action.payload.spinner
      })
  }
})
export const { handleAddtoCart } = commonreducerSlice.actions
export default commonreducerSlice.reducer
