// Common API's
const GetAllRegistry = 'Registry/GetAllRegistries'
const GetRISCEntryData = 'Submission/GetAllSubmissions'
const CrateSubmission = 'Submission/CreateSubmission'
const UpdateSubmission = 'Submission/UpdateSubmission'
const UpdateTermsAndConditions = "User/UpdateTermsAndConditions"
const GetSubmissionHistoryById = "Submission/GetSubmissionHistoryById"
// const GetAllCurrentFlags = "CurrentFlag/GetAllCurrentFlags"
const CreateInviteUser = 'User/CreateInviteUser'
const UpdateInviteUser = 'User/UpdateInviteUser'
const GetAllUsers = 'User/GetAllUsers'
export const ApiUrls = {
  GetAllRegistry,
  GetRISCEntryData,
  CrateSubmission,
  UpdateSubmission,
  UpdateTermsAndConditions,
  GetSubmissionHistoryById,
  CreateInviteUser,
  UpdateInviteUser,
  GetAllUsers
  // GetAllCurrentFlags
}