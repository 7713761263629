import { Check, X } from 'react-feather'
import Avatar from '../../@core/components/avatar'
import toast from 'react-hot-toast'
import { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from "axios"
import Cookies from 'universal-cookie'

const ToastMessage = ({ t, message, status }) => {

  const navigate = useNavigate()

  useEffect(() => {
    if (message === 'unauthorized') {
      navigate('/Unauthorized')
    } else if (message === 'cookie') {
      const localStorageToken = localStorage.getItem('token')
      const savedState = localStorage.getItem("azureAdB2C")
      const b2cData = JSON.parse(savedState)
      if (b2cData && localStorageToken) {
        axios.get(`${b2cData.apiUrl}api/User/GetUser`, {
          headers: { Authorization: `Bearer ${localStorageToken}` }
        }).then((res) => {
          const userData = res.data
          if (userData.success) {
            if (userData.data) {
              localStorage.setItem('userData', JSON.stringify(userData.data))
              const cookies = new Cookies()
              cookies.set('CustomClaims', res.headers["x-set-cookie"], { path: '/' })
              const pathname = window.location.pathname
              navigate('/pageRefresh')
              setTimeout(() => {
                navigate(pathname)
              }, 100)
            }
          } else {
            toast(t => (<ToastMessage t={t} message={"Something Went Wrong..."} status={"danger"} />))
            localStorage.clear()
            window.location.reload()
          }
        }).catch((error) => {
          const errorresponse = error.response
          if (errorresponse) {
            const status = errorresponse.status
            if (status === 403 || status === 401) {
              localStorage.clear()
              window.location.reload()
            } else if (status === 406) {
              navigate('/Unauthorized')
            } else {
              toast(t => (<ToastMessage t={t} message={"Something Went Wrong..."} status={"danger"} />))
              setTimeout(() => {
                localStorage.clear()
                window.location.reload()
              }, 2000)
            }
          }
        })
      }
    }
  }, [message])

  return (
    <Fragment>
      {(message !== 'unauthorized' && message !== 'cookie') &&
        <div className='d-flex'>
          <div className='me-1'>
            <Avatar size='sm' color={status} icon={<Check size={12} />} />
          </div>
          <div className='d-flex flex-column'>
            <div className='d-flex justify-content-between'>
              <h6>{message}</h6>
            </div>
          </div>
          <div>
            <X size={20} className='cursor-pointer' onClick={() => toast.dismiss(t.id)} />
          </div>
        </div>
      }
    </Fragment>
  )
}

export default ToastMessage
