// You can customize the template with the help of this file

//Template config options
const themeConfig = {
  app: {
    appName: "RISC Databank"
  },
  navicons: {
    home: require("@src/assets/images/nav_icons/home1.png"),
    home1: require("@src/assets/images/nav_icons/home2.png"),

    Profile: require("@src/assets/images/nav_icons/account_icon_01.png"),
    Profile1: require("@src/assets/images/nav_icons/account_icon.png"),

    user: require("@src/assets/images/navicons/user_profile.png"),
    PIcon: require("@src/assets/images/Profile_icon.png")
  },
  layout: {
    isRTL: false,
    skin: "light", // light, dark, bordered, semi-dark
    type: "vertical", // vertical, horizontal
    contentWidth: "boxed", // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: "sticky", // static , sticky , floating, hidden
      backgroundColor: "white" // BS color options [primary, success, etc]
    },
    footer: {
      type: "static" // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true, // Enable scroll to top button
    toastPosition: "top-right" // top-left, top-center, top-right, bottom-left, bottom-center, bottom-right
  }
}

export default themeConfig
