const hostpath = `${window.location.origin}/`
// const hostpath = 'https://localhost:7103/'
const azureAdB2CString = localStorage.getItem('azureAdB2C')
const azureAdB2C = azureAdB2CString ? JSON.parse(azureAdB2CString) : ''
const hostname = azureAdB2C ? azureAdB2C.hostname : ''
let headerText = ''
if (hostname === 'https://localhost:44362') {
    headerText = 'Local Environment'
}
const apiUrl = azureAdB2C ? azureAdB2C.apiUrl : ''
const instance = azureAdB2C ? azureAdB2C.instance : ''
const domain = azureAdB2C ? azureAdB2C.domain : ''
const signUpSignInPolicyId = azureAdB2C ? azureAdB2C.signUpSignInPolicyId : ''
const clientId = azureAdB2C ? azureAdB2C.clientId : ''
const GlobalVariable = Object.freeze({
    hostpath,
    BASE_API_URL: `${apiUrl}api/`,
    BASE_REDIRECT_URL: hostname,
    headerText,
    AzureB2CUrl: `${instance}/${domain}/oauth2/v2.0/authorize?p=${signUpSignInPolicyId}&client_id=${clientId}&nonce=defaultNonce&redirect_uri=${hostname}&scope=openid&response_type=code&prompt=login&code_challenge=${azureAdB2C.codeChallenge}&code_challenge_method=S256`,
    AzureB2CLogoutUrl: `${instance}/${domain}/${signUpSignInPolicyId}/oauth2/v2.0/logout?post_logout_redirect_uri=${hostname}&client_id=${localStorage.getItem('token')}`
})
export default GlobalVariable
